<template>
  <div class="mainSection">
    <div class="loading" v-if="state.isLoading">
      <IconsWorker width="30" icon="loading"/>
    </div>
    <div class="settingCell" v-for="socialCell in state.items" :key="(socialCell.key)" v-else>
      <IconsWorker :width="30" :icon="socialCell.icon" class="icon" color="#555"/>
      <DuInput
          class="input"
          type="text"
          :value="socialCell.value"
          :is-required="false"
          @input="(e) => changeValue(socialCell.key, e.value)"
      />
    </div>
    <DuButton text="حفظ" @click="save()" class="saveButton" v-if="!state.isLoading"/>
  </div>
</template>

<script>
import {onMounted, reactive} from "vue";
import AjaxWorker from "../../helpers/AjaxWorker";
import DuInput from "../../components/DuInput";
import DuButton from "../../components/DuButton";
import IconsWorker from "../../components/IconsWorker";
import FixedNotification from "../../components/plugins/fixedNotifications/FixedNotification";

export default {
  name: "ContactInfoSettings",
  components: {IconsWorker, DuButton, DuInput},
  setup() {
    const state = reactive({
      items: [
        { key: 'supportInstagramLink', value: '', icon: 'instagramF', },
        { key: 'supportFacebookLink', value: '', icon: 'facebookF', },
        { key: 'supportTwitterLink', value: '', icon: 'twitterF', },
        { key: 'supportLinkedInLink', value: '', icon: 'linkedinF', },
        { key: 'supportSnapChatLink', value: '', icon: 'snapchatF', },
        { key: 'supportWhatsAppLink', value: '', icon: 'whatsappF', },
        { key: 'supportYoutubeLink', value: '', icon: 'youtubeF', },
        { key: 'supportTelegramLink', value: '', icon: 'telegramF', },
      ],
      isLoading: true,
    });
    const getOldSettings = () => {
      const keys = [];
      state.items.forEach((el) => {
        keys.push(el.key);
      });
      state.isLoading = true;
      new AjaxWorker().request("/transactions.php?action=getSettings&keys=" + keys.join(',')).then((res) => {
        if(res === "NOINTERNETCONNECTION" || !res.state) {
          setTimeout(() => {
            getOldSettings();
          }, 3000);
        } else {
          console.log(res);
          state.items.forEach((el, index) => {
            state.items[index].value = res.data[el.key];
          });
          state.isLoading = false;
        }
      });
    }
    onMounted(() => {
      getOldSettings();
    });
    const changeValue = (key, newValue) => {
      state.items.forEach((el, index) => {
        if(key === el.key) {
          state.items[index].value = newValue;
        }
      });
    }
    const save = () => {
      const newSettings = {};
      state.items.forEach((el, index) => {
        newSettings[el.key] = el.value;
      });
      new AjaxWorker().request("/transactions.php?action=saveSiteSettings", {
        settings: JSON.stringify(newSettings),
      }).then((res) => {
        if(res === "NOINTERNETCONNECTION" || !res.state) {
          FixedNotification.create({
            decription: 'حدث خطاء غير معلوم',
            icon: "errorF",
            color: 'red',
            hideAfter: 3000
          });
        } else {
          FixedNotification.create({
            decription: 'تم حفظ الإعدادات الجديدة',
            icon: "settingF",
            color: 'green',
            hideAfter: 3000
          });
        }
      });
    }
    return {
      save,
      changeValue,
      getOldSettings,
      state,
    }
  }
}
</script>

<style scoped>
.mainSection{
  width: available;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin: 0 20px;
}
.mainSection .settingCell{
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  height: 50px;
}
.mainSection .saveButton{
  place-self: end;
  margin-top: 20px;
}
.mainSection .input{
  flex: 1;
}
.mainSection .icon{
  margin-top: 10px;
}
.mainSection .loading{
  padding: 50px 0;
  display: flex;
  width: available;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
</style>